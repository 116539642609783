import { GET_SMS_RECIPIENTS, CREATE_SMS, GET_SMS } from '@/config/endpoint';
import { apiFilters } from '@/config/axios';
import Req from './AxiosRequest';

const axios = new Req();

class Sms {
	constructor() {
		this.data = new Req(apiFilters);
		this.customDataObject = {};
		this.errors = this.data.errors;
		this.loading = this.data.loading;
		this.pagination = this.data.pagination;
		this.options = {};
	}

	clear() {
		this.data.clear();
	}

	getSms(options) {
		const { method, endpoint } = GET_SMS;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getSmsRecipients(options) {
		const { method, endpoint } = GET_SMS_RECIPIENTS;
		return this.data[method](endpoint, options, true).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	createSms(options) {
		const { endpoint, method } = CREATE_SMS;
		this.options.data = options;
		this.options.config = { headers: { 'Content-Type': 'multipart/form-data' } };
		this.options.url = endpoint;
		this.options.method = method;
		return axios.postFormData(this.options).then((response) => response).catch((errors) => Promise.reject(errors)).finally(() => {
			this.loading = false;
			return null;
		});
	}
}

export default Sms;
