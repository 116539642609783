<template>
	<div class="animated fadeIn">
		<b-row
			v-if="$can('sms_notification', 'create')"
			class="mb-4">
			<b-col>
				<b-button
					:to="{ name: 'SendSms'}"
					class="float-right"
					variant="success">
					{{ translate('new') }}
				</b-button>
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				<div class="table-responsive">
					<table class="table table-hover table-striped text-nowrap">
						<thead>
							<tr>
								<th class="border-top-0">
									#
								</th>
								<!-- <th
									class="border-top-0 pointer"
									@click="sortByField('user_id')">
									{{ translate('user_id') }}
									<sort field="user_id" />
								</th>
								<th
									class="border-top-0 pointer"
									@click="sortByField('username')">
									{{ translate('username') }}
									<sort field="username" />
								</th> -->
								<th class="border-top-0">
									{{ translate('message') }}
								</th>
								<th class="border-top-0">
									{{ translate('recipients') }}
								</th>
								<th class="border-top-0">
									{{ translate('filters') }}
								</th>
								<th
									class="border-top-0 pointer"
									@click="sortByField('dispatch_time')">
									{{ translate('dispatch_time') }}
									<sort field="dispatch_time" />
								</th>
								<th class="border-top-0 text-center">
									{{ translate('options') }}
								</th>
							</tr>
						</thead>
						<tbody v-if="!loading && hasData">
							<tr
								v-for="(item, index) in data"
								:key="index">
								<td class="align-middle">
									{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
								</td>
								<!-- <td class="align-middle">
									<span class="badge badge-primary">{{ item.attributes.user_id }}</span>
								</td>
								<td class="align-middle">
									{{ item.attributes.username }}
								</td> -->
								<td class="align-middle white-space-normal cell-custom-width">
									{{ item.attributes.message }}
								</td>
								<td class="align-middle">
									{{ item.attributes.recipients }}
								</td>
								<td class="align-middle white-space-normal cell-custom-width">
									<div v-if="formatCountries(item.attributes.filters).length">
										<b>{{ translate('countries') }}:</b>
										<span
											:id="'tooltip' + item.id">
											<span
												v-for="(country, key) in formatCountries(item.attributes.filters, true)"
												:key="key"
												class="pointer text-primary">
												{{ translate(country.toLowerCase()) }}<span v-if="key !== formatCountries(item.attributes.filters, true).length-1">,</span><span v-else-if="formatCountries(item.attributes.filters).length > maxCountries">...</span>
											</span>
										</span>
										<b-tooltip
											:target="'tooltip' + item.id"
											placement="right"
											triggers="hover">
											<span
												v-for="(country, key) in formatCountries(item.attributes.filters)"
												:key="key">
												{{ translate(country.toLowerCase()) }}<span v-if="key !== formatCountries(item.attributes.filters).length-1">,</span>
											</span>
										</b-tooltip>
									</div>
								</td>
								<td class="align-middle">
									{{ $moment(item.attributes.dispatch_time.date).format(dateFormat) }}
								</td>
								<td class="text-center">
									<a
										v-for="image in item.attributes.image_url"
										:key="image"
										v-b-tooltip.hover
										:title="translate('view_image')"
										variant="primary"
										class="btn btn-xs mr-1 bg-primary-alt"
										target="_blank"
										:href="image">
										<i class="fa fa-eye" />
									</a>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</b-col>
		</b-row>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
	</div>
</template>

<script>
import { PAGINATION } from '@/settings/RequestReply';
import {
	Sms as messages, Countries, Grids,
} from '@/translations';
import FiltersParams from '@/mixins/FiltersParams';
import { MMMDYHMS_FORMAT } from '@/settings/Dates';
import Sms from '@/util/Sms';

export default {
	name: 'SmsGrid',
	messages: [messages, Countries, Grids],
	mixins: [FiltersParams],
	data() {
		return {
			sms: new Sms(),
			dateFormat: MMMDYHMS_FORMAT,
			maxCountries: 4,
		};
	},
	computed: {
		loading() {
			try {
				return !!this.sms.data.loading;
			} catch (error) {
				return false;
			}
		},
		errors() {
			return this.sms.data.errors;
		},
		pagination() {
			return this.sms.data.pagination;
		},
		data() {
			try {
				const { data } = this.sms.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			try {
				const response = this.data.length;
				return !!response;
			} catch (error) {
				return false;
			}
		},
	},
	mounted() {
		this.getMessages();
	},
	methods: {
		getMessages() {
			this.sms.getSms();
		},
		parseFilters(filters) {
			return JSON.parse(filters);
		},
		formatCountries(filters, hasLimit = false) {
			try {
				const countries = this.parseFilters(filters).countries.split(',');
				if (hasLimit && countries.length > this.maxCountries) {
					return countries.slice(0, this.maxCountries);
				}
				return countries;
			} catch (error) {
				return [];
			}
		},
		getDataFiltered(page) {
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}
			this.sms.getSms(options);
		},
	},
};
</script>

<style scoped>
.cell-custom-width {
	min-width: 300px;
	max-width: 350px;
}
</style>
